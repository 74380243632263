@use "src/_app/styles/mixins.scss" as *;
.rating_root {
  margin-left: 12px;
  justify-content: center;
}

.rate {
  &__wrapper {
    max-width: 100%;
    width: 100%;
    height: 180px;

    border: 1px solid var(--blogRateBorder);
    border-radius: 16px;

    margin: 0 auto;

    display: grid;
    grid-template-columns: max-content;
    grid-template-rows: max-content max-content 20px;
    row-gap: 16px;
    align-items: center;
    justify-content: center;

    padding: 32px;

    @include respond(tablet) {
      padding: 24px;
    }
  }

  &__title {
    @include tTitle_3-semibold;
    color: var(--primary);
    margin: 0;
    text-align: center;
  }

  &__text {
    @include tCaption;
    color: var(--text_1);
    text-align: center;
  }
}
